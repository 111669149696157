import { httpsCallable } from "firebase/functions";

import { functions } from "../../../firebase";

const setAsAdmin = httpsCallable(functions, "setAsAdmin");
const setAsUser = httpsCallable(functions, "setAsUser");
const deleteUserByUid = httpsCallable(functions, "deleteUserByUid");
const associateUserWithOperator = httpsCallable(functions, "associateUserWithOperator");

export const upgradeToAdmin = async (uid) => {
  return setAsAdmin({ uid });
};

export const downgradeToUser = async (uid) => {
  return setAsUser({ uid });
};

export const deleteUser = async () => {
  return deleteUserByUid();
};


export const associateUserWithFleetOperator = async (uid, fleetOperatorId) => {
  return associateUserWithOperator({ uid, fleetOperatorId });
}