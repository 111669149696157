export const TankIssueConfig = {
  reportConfig: {
    reportId: "lh8y02zl7sfELKN5DA4i",
  },
  fieldConfig: {
    activityName: {
      label: "Activity",
      tableRank: -1,
      detailsRank: 34, 
      editable: false,
      type: "STRING",
    },
    activityRebateEligiable: {
      label: "Rebate eligible",
      tableRank: -1,
      detailsRank: 34,
      editable: false,
      type: "BOOLEAN",
    },
    areaForFleetUseFarmName: {
      label: "Area name",
      tableRank: -1,
      detailsRank: 40,
      editable: false,
      type: "STRING",
    },
    areaForFleetUseSizeHectares: {
      label: "Area size",
      tableRank: -1,
      detailsRank: 41,
      editable: false,
      type: "NUMBER",
    },
    areaforFleetUseName: {
      label: "Section name",
      tableRank: -1,
      detailsRank: 43,
      editable: false,
      type: "STRING",
    },
    companyId: {
      label: "companyId",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    createdBy: {
      label: "Created by",
      tableRank: -1,
      detailsRank: 6,
      editable: false,
      type: "STRING",
    },
    deviceCaptured: {
      label: "Device captured date",
      tableRank: -1,
      detailsRank: -7,
      editable: false,
      type: "DATE",
    },
    documentNumber: {
      label: "Document #",
      tableRank: 4,
      detailsRank: 2,
      editable: false,
      type: "STRING",
    },
    driverEmployeeCode: {
      label: "Driver employee code",
      tableRank: -1,
      detailsRank: 12,
      editable: false,
      type: "STRING",
    },
    driverIdentification: {
      label: "Driver ID",
      tableRank: -1,
      detailsRank: 13,
      editable: false,
      type: "STRING",
    },
    driverNameAndSurname: {
      label: "Fleet operator",
      tableRank: 5,
      detailsRank: 14,
      editable: false,
      type: "STRING",
    },
    driverNickName: {
      label: "Driver nickname",
      tableRank: -1,
      detailsRank: 15,
      editable: false,
      type: "STRING",
    },
    driverOperatorSign: {
      label: "Signed by",
      tableRank: -1,
      detailsRank: 26,
      editable: false,
      type: "STRING",
    },
    driverOperatorSignedImage: {
      label: "Operator",
      tableRank: -1,
      detailsRank: 27,
      editable: false,
      type: "IMAGE",
    },
    fleetDefaultActivity: {
      label: "Default fleet activity",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    fleetDescription: {
      label: "Fleet description",
      tableRank: -1,
      detailsRank: 19,
      editable: false,
      type: "STRING",
    },
    fleetDisplayName: {
      label: "Vehicle",
      tableRank: 3,
      detailsRank: 20,
      editable: false,
      type: "STRING",
    },
    fleetGroupName: {
      label: "Fleet group name",
      tableRank: -1,
      detailsRank: 21,
      editable: false,
      type: "STRING",
    },
    fleetOdoOrHourBased: {
      label: "Fleet ODO or Hour",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "STRING",
    },
    fleetPreviousFillingDate: {
      label: "Previous filling date",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "DATE",
    },
    fleetRegistration: {
      label: "Registration",
      tableRank: -1,
      detailsRank: 5,
      editable: false,
      type: "STRING",
    },
    fleetUseFleetForRebateClaim: {
      label: "Fleet used for rebate claim",
      tableRank: -1,
      detailsRank: 26,
      editable: false,
      type: "BOOLEAN",
    },
    gpsLocation: {
      label: "Location",
      tableRank: -1,
      detailsRank: 8,
      editable: false,
      type: "COORDINATE",
    },
    isModified: {
      label: "Entry modified",
      tableRank: -1,
      detailsRank: 29,
      editable: false,
      type: "BOOLEAN",
    },
    litres: {
      label: "Litres",
      tableRank: 2,
      detailsRank: 2,
      editable: true,
      type: "NUMBER",
    },
    odoOrHourReading: {
      label: "ODO",
      tableRank: -1,
      detailsRank: 10,
      editable: true,
      type: "NUMBER",
    },
    odoOrHourReadingPhoto: {
      label: "ODO photo",
      tableRank: -1,
      detailsRank: 11,
      editable: false,
      type: "IMAGE",
    },
    tankMeterReadingStartPhoto: {
      label: "Tank reading before",
      tableRank: -1,
      detailsRank: 17,
      editable: false,
      type: "IMAGE",
    },
    tankMeterReadingEndPhoto: {
      label: "Tank reading after",
      tableRank: -1,
      detailsRank: 18,
      editable: false,
      type: "IMAGE",
    },
    productName: {
      label: "Product name",
      tableRank: -1,
      detailsRank: 32,
      editable: false,
      type: "STRING",
    },
    pumpAttendantSigned: {
      label: "Pump attendant",
      tableRank: -1,
      detailsRank: 13,
      editable: false,
      type: "STRING",
    },
    pumpAttendantSignedImage: {
      label: "Attendant signature",
      tableRank: -1,
      detailsRank: 13,
      editable: false,
      type: "IMAGE",
    },
    receivedOnServer: {
      label: "Server receipt",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "DATE",
    },
    syncCompleted: {
      label: "Sync complete",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    },
    tankFuelType: {
      label: "Fuel type",
      tableRank: -1,
      detailsRank: 2,
      editable: false,
      type: "STRING",
    },
    tankIsMobile: {
      label: "Tank is mobile",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    },
    tankMeterReadingEnd: {
      label: "Tank reading after",
      tableRank: -1,
      detailsRank: 17,
      editable: false,
      type: "NUMBER",
    },
    tankMeterReadingStart: {
      label: "Tank reading before",
      tableRank: -1,
      detailsRank: 16,
      editable: false,
      type: "NUMBER",
    },
    tankName: {
      label: "Tank name",
      tableRank: 1,
      detailsRank: 1,
      editable: false,
      type: "STRING",
    },
    tankSizeLitres: {
      label: "Tank size",
      tableRank: -1,
      detailsRank: 30,
      editable: false,
      type: "NUMBER",
    },
    transactionDate: {
      label: "Date",
      tableRank:0,
      detailsRank: 0,
      editable: true,
      type: "DATE",
    },
    transactionReviewed: {
      label: "Reviewed",
      tableRank: -1,
      detailsRank: -1,
      editable: false,
      type: "BOOLEAN",
    }
  },
};
